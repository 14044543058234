import { Padding } from "@mui/icons-material";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./css/phnInput.css";

export function PhoneNum({ name, placeholder, isValid, setIsValid }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  const validatePhoneNumber = (phone) => {
    const countryCodeLength = 2; // +91 has 2 digits
    // Check if phone number is longer than just the country code
    if (phone && phone.length > countryCodeLength) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setPhoneNumber(phone);
  };
  return (
    <div className="flex flex-col justify-center">
      <PhoneInput
        value={phoneNumber}
        onChange={validatePhoneNumber}
        inputClass={`phnInput adjustWidthPhn ${
          !isValid ? "border-red-500" : ""
        }`} // Add error class if invalid
        country="in"
        required
        placeholder="Phone"
        preferredCountries={["in"]}
        specialLabel={false}
        containerStyle={{ display: "flex" }}
        dropdownClass="adjustWidthPhn"
        inputProps={{
          name: name || "from_phnNum", // Use passed name prop or fallback to "from_phnNum",
          autoFocus: true,
        }}
      />
    </div>
  );
}
export function PhoneNum1({ placeholder }) {
  return (
    <div className="flex phoneNum1 justify-center">
      <PhoneInput
        name="from_phnNum"
        inputClass="phnInput1 adjustWidthPhn"
        country="in"
        placeholder="Phone"
        preferredCountries={["in"]}
        specialLabel={false}
        containerStyle={{ display: "flex" }}
        // dropdownStyle={{ width: "30rem" }}
        dropdownClass="adjustWidthPhn"
        inputProps={{
          name: "from_phnNum",
          required: true,
          // autoFocus: true,
        }}
      />
    </div>
  );
}

export function PhoneNumFooter({ placeholder }) {
  return (
    <div className="flex relative phoneNum1 phnInputFooter justify-center">
      <PhoneInput
        name="from_phnNum"
        inputClass="phnInput1 footerPhnInput"
        country="in"
        placeholder="Phone"
        preferredCountries={["in"]}
        specialLabel={false}
        containerStyle={{ display: "flex" }}
        // dropdownStyle={{ width: "30rem" }}
        dropdownClass="adjustWidthPhn"
        inputProps={{
          name: "from_phnNum",
          required: true,
          // autoFocus: true,
        }}
      />

      <button
        style={{ borderRadius: "4px" }}
        className="text-[16px] absolute bg-[#70aa26] w-[90px] text-white h-full right-0"
      >
        <a href="https://wa.me/919216041313/?text=Hello">Submit</a>
      </button>
    </div>
  );
}
